:root {
    --fontFamily1: Verdana;
    --fontFamily2: Verdana;

    --mainColor: #03045e;
    --footerBG: #03045e;
    --defaultBtnColor: #48cae4;
    --defaultBtnColor-2: #03045e;
    --defaultBtnColor-3: #5a7500;
    --optionalColor: #48cae4;
    --whiteColor: #ffffff;
    --blackColor: #252525;
    --paragraphColor: #727695;
    --mainColor-2: #4f8201;
    --mainColor-3: #4d4d4d;
    --mat-paginator: #e6e147;
    --cardDocumentColor: #caf0f8;
    --bodyBgColor: #f2f2f2;
    --bodyBgColor-2: #c2d9ab;
    --bodyBgColor-3: #e4e4e4;
    // --bodyContact:#4f8201;
    --fontSize: 15px;
    --transition: 0.5s;
    --maintophead : linear-gradient(to right, rgb(3 4 94), rgb(2 62 138),rgb(0 119 182));
    --footer-2 : linear-gradient(to right, rgb(3 4 94), rgb(2 62 138),rgb(0 119 182));
}
// :root {
//     --fontFamily1: Verdana;
//     --fontFamily2: Verdana;

//     --mainColor: #ff1949;
//     --footerBG: #252525;
//     --defaultBtnColor: #ff1949;
//     --defaultBtnColor-2: #ff1949;
//     --defaultBtnColor-3: #fb1a47;
//     --optionalColor: #0eb582;
//     --whiteColor: #ffffff;
//     --blackColor: #252525;
//     --paragraphColor: #727695;

//     --mainColor-2: #ffffff;
//     --mainColor-3: #ff1949;
//     --mat-paginator: #ffffff;
//     --cardDocumentColor: #ffffff;
//     --bodyBgColor: #ffffff;
//     --bodyBgColor-2: #ffffff;
//     --bodyBgColor-3: #ffffff;
//     --fontSize: 15px;
//     --transition: 0.5s;
// }
